import { BaseSyntheticEvent, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Graph from "../../../Components/Graphs/Graph";
import { fetchMarketingSpend } from "../../../Services/films/films";
import { Purify } from "../../../Components/Utils/HTMLCleaners";
import COLORS from "../../../Utils/colors";

import BarGraph from "../../../Components/Graphs/BarGraph";
import { Histogram } from "../../../Components/Graphs/Histogram";
import logger from "../../../Utils/logging";
import { MarketingData } from "./types";
import PieGraph from "./PieGraph";
import Explicit from "./Explicit";
import StatsType1 from "../../../Components/Stats/StatsType1";

export default function MarketingSpend() {
	let { filmId } = useParams();
	const [data, setData] = useState<MarketingData>();
	const { getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		async function getMarketingData() {
			const accessToken = await getAccessTokenSilently();
			const data = await fetchMarketingSpend(accessToken, filmId);
			setData(data);
		}
		getMarketingData();
	}, [getAccessTokenSilently, fetchMarketingSpend]);

	return (
		<div className="box-border flex flex-col items-center w-full h-full">
			<div className="w-4/5">
				{data && data.highlights && (
					<StatsType1 stats={data.highlights} />
				)}
			</div>
			<div className="flex flex-row flex-wrap w-4/5 gap-8">
				<div className="p-3 border-[1px] border-solid border-light w-[45%]">
					<h3>Paid Ads Spend</h3>
					<div className="flex flex-col items-center justify-center w-full min-h-[30vh]">
						{data && data.PaidAds && (
							<>
								<div className="w-[30vh] h-[30vh]">
									<PieGraph
										data={data.PaidAds}
										graphOptions={{}}
									/>
								</div>

								<Explicit data={data.PaidAds} />
							</>
						)}
					</div>
				</div>
				<div className="p-3 border-[1px] border-solid border-light w-[45%]">
					<h3>Specialty Marketing Spend</h3>
					<div className="flex flex-col items-center justify-center w-full min-h-[30vh]">
						{data && data.SpecialtyMarketing && (
							<>
								<div className="w-[30vh] h-[30vh]">
									<PieGraph
										data={data.SpecialtyMarketing}
										graphOptions={{}}
									/>
								</div>

								<Explicit data={data.SpecialtyMarketing} />
							</>
						)}
					</div>
				</div>
				<div className="p-3 border-[1px] border-solid border-light w-[45%]">
					<h3>Content Creation Spend</h3>
					<div className="flex flex-col items-center justify-center w-full min-h-[30vh]">
						{data && data.ContentCreation && (
							<>
								<div className="w-[30vh] h-[30vh] ">
									<PieGraph
										data={data.ContentCreation}
										graphOptions={{}}
									/>
								</div>
								<Explicit data={data.ContentCreation} />
							</>
						)}
					</div>
					{/* <div className="p-3 border-[1px] border-solid border-light hover:border-2 hover:border-solid hover:border-accent1">
						<h2>Campaign</h2>
						<div className="flex flex-row items-center justify-center w-full h-full ">
							<div className="w-4/5 h-4/5"></div>
						</div>
					</div> */}
				</div>
			</div>
		</div>
	);
}
